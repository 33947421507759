<template>
  <div class="home-index frame-content-common-2022">
    <el-carousel>
      <el-carousel-item v-for="(item) in dataSide" :key="item.key">
        <div class="banner container_f" :style="getStyle(item)">
          <div class="txt">
            <p class="txt_1">{{ item.text1 }}</p>
            <span>{{ item.text2 }}</span>
          </div>
          <div class="text_small">
            <p>{{ item.text3 }}</p>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="lin_new container_f d-flex justify-content-between align-items-center">
      <!--      <div></div>-->


      <div class="d-flex align-items-center">
        <div class="txt">
          <span>tin mới nhất</span>
        </div>
        <div class="box">
          <i class="el-icon-back"></i>
        </div>
        <div class="box" style="border-left: 1px solid #fff">
          <i class="el-icon-right"></i>
        </div>
        <div class="txt pl-2">
          <span>Dự án domea đi vào hoạt động</span>
        </div>
      </div>


      <div class="icon_right">
        <i class="el-icon-top-right"></i>
      </div>
    </div>
    <!--    // Về Chúng tôi -->
    <div class="container_f">
      <div class="row" style="border-bottom: 1px solid #DADADA">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="ct_h2_about">
            <span>{{ dataVct.name }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="des_about">
            <p v-html="dataVct.description"></p>
          </div>
          <div class="more" @click="toMore()">
            <span>xem thêm</span>
            <i class="el-icon-top-right"></i>
          </div>
        </div>
      </div>
      <div class="about row" ref="targetElement">
        <div class="col-md-6">
          <div class="content_left">
            <img :src="dataVct.image_left" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div class="content_right">
            <img :src="dataVct.image_right" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--    // kiến tạo tương lai -->
    <div class="container_f future">
      <div class="row_1">
        <el-row>
          <el-col :md="8" :xs="24">
            <div class="title d-flex flex-column">
              <span class="txt_1">{{ dataKttl.text1 }}</span>
              <span class="txt_2">{{ dataKttl.text2 }}</span>
            </div>
          </el-col>
          <el-col :md="4">
            <div class="box_fu is_none_mb"></div>
          </el-col>
          <el-col :md="4" :xs="24">
            <div class="box_fu item_1 borderTBLR_radius txt_box is_none_mb" v-if="dataKttl.top">
              <!--              <span class="txt_big">{{ dataKttl.top.text1 }}</span>-->
              <span class="txt_big">{{ currentNumberTop }}+ </span>
              <span class="txt_small">{{ dataKttl.top.text2 }}</span>
            </div>
          </el-col>
          <el-col :md="4">
            <div class="box_fu item_2 is_none_mb">
              <img style="height: 240px" src="@/static/images/home/image1.png" alt="">
            </div>
          </el-col>
          <el-col :md="4">
            <div class="box_fu item_3 borderTBLR_radius is_none_mb">
              <img class="imgBr1" src="@/static/images/home/top.png" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="row_2 is_none_mb">
        <el-row>
          <el-col :span="4">
            <div class="box_fu is_none_mb"></div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu is_none_mb"></div>
          </el-col>
          <el-col :span="4" :xs="24">
            <div class="box_fu item_4 borderTBLR_radius txt_box" v-if="dataKttl.left">
              <!--              <span class="txt_big">{{ dataKttl.left.text1 }}</span>-->
              <span class="txt_big">{{ currentNumberLeft }} </span>
              <span class="txt_small">{{ dataKttl.left.text2 }}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu item_5 borderRadius50 is_none_mb">
              <div class="item_5_1 borderRadius50">
                <div class="item_5_1_2 borderRadius50">
                  <div class="item_5_1_3 borderRadius50">
                    <div class="item_5_1_4 borderRadius50"></div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="4" :xs="24">
            <div class="box_fu item_6 borderTBLR_radius txt_box" v-if="dataKttl.right">
              <!-- <span class="txt_big">{{ dataKttl.right.text1 }}</span>-->
              <span class="txt_big">{{ currentNumberRight }}tỷ </span>
              <span class="txt_small">{{ dataKttl.right.text2 }}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu item_7 borderTBRL_radius is_none_mb">
              <img class="imgBr2" src="@/static/images/home/bottom.png" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="row_3 is_none_mb">
        <el-row>
          <el-col :span="4">
            <div class="box_fu item_8 borderTBRL_radius is_none_mb">
              <img class="imgBr2" src="@/static/images/home/left.png" alt="">
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu item_9 borderRadius50 is_none_mb">
              <img class="imgBr3" src="@/static/images/home/right.png" alt="">
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu item_10 borderTBLR_radius is_none_mb">
              <img class="imgBr1" src="@/static/images/home/image2.png" alt="">
            </div>
          </el-col>
          <el-col :span="4" :xs="24">
            <div class="box_fu item_11 txt_box" v-if="dataKttl.bottom">
              <!--              <span class="txt_big">{{ dataKttl.bottom.text1 }}</span>-->
              <span class="txt_big">{{ currentNumberBottom }}+ </span>
              <span class="txt_small">{{ dataKttl.bottom.text2 }}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu item_12 borderRadius50 is_none_mb"></div>
          </el-col>
          <el-col :span="4">
            <div class="box_fu item_13 is_none_mb">
              <img src="@/static/images/home/image3.png" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!--    // kiến tạo tương lai mobile -->
      <div class="future_mb" style="display: none">
        <div class="box_futu_main">
          <div class="box_l left_1 d-flex flex-column justify-content-center align-items-center">
            <span class="txt_big">{{ currentNumberTop }}+ </span>
            <span v-if="dataKttl.top" class="txt_small">{{ dataKttl.top.text2 }}</span>
          </div>
          <div class="box_r right_1 d-flex flex-column justify-content-center align-items-center">
          <span class="txt_big">{{ currentNumberRight }}tỷ </span>
          <span v-if="dataKttl.right" class="txt_small">{{ dataKttl.right.text2 }}</span>
          </div>
        </div>
        <div class="box_futu_main">
          <div class="box_r left_2 d-flex flex-column justify-content-center align-items-center">
            <span class="txt_big">{{ currentNumberLeft }} </span>
            <span v-if="dataKttl.left" class="txt_small">{{ dataKttl.left.text2 }}</span>
          </div>
          <div class="box_l right_2 d-flex flex-column justify-content-center align-items-center">
            <span class="txt_big">{{ currentNumberBottom }}+ </span>
            <span class="txt_small" v-if="dataKttl.bottom">{{ dataKttl.bottom.text2 }}</span>
          </div>
        </div>
      </div>


    </div>




    <!--    // Dự án đang đầu tư -->
    <div class="container_f project partner">
      <div class="title">
        <h3>{{ dataDaddt.text1 }}</h3>
        <span>{{ dataDaddt.text2 }}</span>
        <p>{{ dataDaddt.text3 }}</p>
      </div>
      <el-row>
        <el-col :md="12" :xs="24">
          <div class="list_project">
            <div class="vt1 cupo" @click="showContent(dataDaddt.logo8)" v-if="dataDaddt.logo8">
              <img v-if="vtActive == 8" class="vt-rel" src="@/static/images/home/vt_8_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_8.svg" alt="">
              <img class="vt-ab" :src="dataDaddt.logo8.image" alt="">
            </div>
            <div class="vt2 cupo" @click="showContent(dataDaddt.logo1)" v-if="dataDaddt.logo1">
              <img v-if="vtActive == 1" class="vt-rel" src="@/static/images/home/vt_1_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_1.svg" alt="">
              <img class="vt-ab" :src="dataDaddt.logo1.image" alt="">

            </div>
            <div class="vt3 cupo" @click="showContent(dataDaddt.logo2)" v-if="dataDaddt.logo2">
              <img v-if="vtActive == 2" class="vt-rel" src="@/static/images/home/vt_2_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_2.svg" alt="">
              <img style="left: 16px" class="vt-ab" :src="dataDaddt.logo2.image" alt="">
            </div>
            <div class="vt4 cupo" @click="showContent(dataDaddt.logo3)" v-if="dataDaddt.logo3">
              <img v-if="vtActive == 3" class="vt-rel" src="@/static/images/home/vt_3_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_3.svg" alt="">
              <img style="left: 16px" class="vt-ab" :src="dataDaddt.logo3.image" alt="">
            </div>
            <div class="vt5 cupo" @click="showContent(dataDaddt.logo4)" v-if="dataDaddt.logo4">
              <img v-if="vtActive == 4" class="vt-rel" src="@/static/images/home/vt_4_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_4.svg" alt="">
              <img style="top: 70px" class="vt-ab" :src="dataDaddt.logo4.image" alt="">
            </div>
            <div class="vt6 cupo" @click="showContent(dataDaddt.logo5)" v-if="dataDaddt.logo5">
              <img v-if="vtActive == 5" class="vt-rel" src="@/static/images/home/vt_5_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_5.svg" alt="">
              <img style="top: 30px" class="vt-ab" :src="dataDaddt.logo5.image" alt="">
            </div>
            <div class="vt7 cupo" @click="showContent(dataDaddt.logo6)" v-if="dataDaddt.logo6">
              <img v-if="vtActive == 6" class="vt-rel" src="@/static/images/home/vt_6_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_6.svg" alt="">
              <img class="vt-ab" :src="dataDaddt.logo6.image" alt="">
            </div>
            <div class="vt8 cupo" @click="showContent(dataDaddt.logo7)" v-if="dataDaddt.logo7">
              <img v-if="vtActive == 7" class="vt-rel" src="@/static/images/home/vt_7_ac.svg" alt="">
              <img v-else class="vt-rel" src="@/static/images/home/vt_7.svg" alt="">
              <img class="vt-ab" :src="dataDaddt.logo7.image" alt="">
            </div>
          </div>
        </el-col>
        <el-col :md="12" :xs="24">

          <div class="data_right_scroll" ref="scrollContainer" @scroll="scrollItem()" @mousemove="mouseMove()">
            <div class="fade-top"></div>
            <div
              class="content_data_project"
              v-for="item in listDataRight"
              :key="item.vt"
              :id="item.vt"
              :ref="'item-' + item.vt"
              @click="activeLeft(item)"

            >
              <div class="logo" :id="'logo' + item.vt">
                <img :src="item.image_new" alt="">
              </div>
              <div class="title_content_data">
                <span>{{ item.title }}</span>
              </div>
              <div class="glory" :ref="'dess-' + item.vt">
                <span v-html="item.description"></span>
              </div>
            </div>

            <div class="fade-bottom"></div>

          </div>


        </el-col>
      </el-row>
    </div>
    <!--    // đối tác của chúng tôi-->
    <div style="background: #FBFBFF">
      <div class="container_f partner">
        <div class="title">
          <h3>{{ dataDt.text1 }}</h3>
          <span>{{ dataDt.text2 }}</span>
        </div>
        <div class="list_logo_partner bg_bar">
          <div class="row">
            <div class="col-md-3 col-6" v-for="item in dataDt.image" :key="item.key">
              <div class="image_logo">
                <img :src="item.url" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    // Tin tức mới-->
    <div class="container_f partner">
      <div class="title">
        <h3>{{ dataTt.text1 }}</h3>
        <span>{{ dataTt.text2 }}</span>
      </div>
      <div
        class="china-panigation-1988 d-flex justify-content-end pt-1"
      >
        <el-pagination class="notranslate"
          background
          layout="prev, pager, next"
          :page-size="pagination.per_page"
          :total="pagination.total"
          :current-page="filter.page"
          @current-change="changeCurrentPage"
        >
        </el-pagination>
      </div>
      <div class="post_list">
        <!--        kiểu 1 -->
        <div class="row" v-if="this.dataTt.layout == '1'">
          <div class="col-md-4 layout_tt_4">
            <div v-for="(item, index) in listPost" :key="item.id">
              <div class="post_item post_item_layout_1" v-if="index < 1">
                <div class="thumbnail" @click="goToDetail(item)">
                  <img :src="item.image" alt="">
                </div>
                <div class="title_post">
                  <el-link @click="goToDetail(item)"><span>{{ item.title }}</span></el-link>
                </div>
                <div class="date">
                  <i class="el-icon-date pr-1"></i>
                  <span>{{ item.created_at }}</span>
                </div>
                <div class="dess_post">
                  <span>{{ item.content_title }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="d-flex flex-column">
              <div v-for="(item, index) in listPost" :key="item.id">
                <div v-if="index > 0" class="post_item post_item_layout_1 d-flex align-items-center">
                  <div class="thumbnail thumbnail_layout_1" @click="goToDetail(item)">
                    <img :src="item.image" alt="">
                  </div>
                  <div>
                    <div class="title_post" @click="goToDetail(item)">
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="date">
                      <i class="el-icon-date pr-1"></i>
                      <span>{{ item.created_at }}</span>
                    </div>
                    <!--<div class="dess_post">
                      <span>{{ item.content_title }}</span>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        kiểu 2 -->
        <div class="row" v-if="this.dataTt.layout == '2'">
          <div class="col-md-4" v-for="(item) in listPost" :key="item.id">
            <div class="post_item post_item_layout_1 post_item_layout_2">
              <div class="thumbnail thumbnail_layout_2" @click="goToDetail(item)">
                <img :src="item.image" alt="">
              </div>
              <div class="title_post">
                <el-link @click="goToDetail(item)"><span>{{ item.title }}</span></el-link>
              </div>
              <div class="date">
                <i class="el-icon-date pr-1"></i>
                <span>{{ item.created_at }}</span>
              </div>
              <div class="dess_post">
                <span>{{ item.content_title }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--        kiểu 3 -->
        <div class="row" v-if="this.dataTt.layout == '3'">
          <div class="col-md-3" v-for="item in listPost" :key="item.id">
            <div class="post_item post_item_type_3">
              <div class="thumbnail thumbnail_layout_3" @click="goToDetail(item)">
                <img :src="item.image" alt="">
              </div>
              <div class="title_post">
                <el-link @click="goToDetail(item)"><span>{{ item.title }}</span></el-link>
              </div>
              <div class="date">
                <i class="el-icon-date pr-1"></i>
                <span>{{ item.created_at }}</span>
              </div>
              <div class="dess_post">
                <span>{{ item.content_title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "HomeIndex",
    metaInfo() {
      return {
        title: "Trang chủ",
      };
    },
    components: {},
    data() {
      return {
        loading: true,
        vtActive: 1,

        text: '',

        dataSide: {},
        dataVct: {},
        dataKttl: {},
        dataDaddt: {},
        dataDt: {},
        dataTt: {},


        filter: {
          page: 1,
          per_page: 1,
        },

        listPost: [],
        pagination: {
          total: 0,
          count: 0,
          per_page: 0,
          current_page: 1,
          total_pages: 0,
        },

        listDataRight: [],

        // chạy số
        currentNumberTop: 0,
        currentNumberRight: 0,
        currentNumberBottom: 0,
        currentNumberLeft: 0,
        targetElementPosition: null,
        maxNumber: 100, // Số cố định
        isCounting: false,
        isReached: false,

        number1: 0,
        number2: 0,
        number3: 0,
        number4: 0,

        scrollElement: {},
        translatedLanguage: "",
        checkScroll: false,

      };
    },
    mounted() {
      var notranslateDiv = document.querySelector(".notranslate");
      if (notranslateDiv) {
        notranslateDiv.classList.add("notranslate");
      }
      this.fetchData();
      window.addEventListener('scroll', this.handleScroll);
      // this.isCounting = false;
      this.isMobile();
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      scrollItem() {
        if(this.checkScroll == false) {
          return;
        }
        /*const container = this.$refs.scrollContainer;
        const scrolledIndex = Math.floor(container.scrollTop / container.clientHeight);
        const scrolledItem = this.listDataRight[scrolledIndex];
        this.vtActive = scrolledItem.vt;*/

        const container = this.$refs.scrollContainer;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;

        this.listDataRight.forEach(item => {
          // const itemRef = this.$refs['item-' + item.vt][0];
          const itemRef = this.$refs['dess-' + item.vt][0];
          const itemTop = itemRef.offsetTop;
          const itemBottom = itemTop + itemRef.clientHeight;

          if (itemBottom > containerTop && itemTop < containerBottom) {
            this.vtActive = item.vt
          }
        });
      },

      mouseMove() {
        this.checkScroll = true
      },
      showContent(e) {
        this.checkScroll = false;
        this.vtActive = e.vt;
        /*let element = this.$refs.itemRefs.find(
          ref => ref.id == e.vt,
        )
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
        new Promise(resolve => setTimeout(resolve, 100)); // Chờ 1 giây trước khi click
        element.click();*/

        const item = this.$refs['item-' + e.vt][0];
        const container = this.$refs.scrollContainer;

        const currentWidth = window.innerWidth;

        if(this.isMobile()) {
          container.scrollTop = item.offsetTop - container.offsetTop + 160;
          return
        }
        if (currentWidth < 750) {
          container.scrollTop = item.offsetTop - container.offsetTop + 160;
          return
        }
        if (item && container) {
          container.scrollTop = item.offsetTop - container.offsetTop - 70;
        }
      },

      goToDetail(item){
        this.$router.push({ name: "new-show", params: { slug: item.slug } });
      },
      handleScroll() {
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)googtrans\s*=\s*([^;]*).*$)|^.*$/, "$1");
        if (cookieValue) {
          const languageCode = cookieValue.split('/')[2];
          if (languageCode == 'en') {
            this.currentNumberTop = this.number1,
              this.currentNumberRight = this.number2,
              this.currentNumberBottom = this.number3,
              this.currentNumberLeft = this.number4
          }
        } else {
          this.targetElementPosition = this.$refs.targetElement.offsetTop;
          if (!this.isCounting && window.pageYOffset >= this.targetElementPosition) {
            this.startCounting1();
            this.startCounting2();
            this.startCounting3();
            this.startCounting4();
            this.isCounting = false;
          }
        }
      },
      startCounting1() {
        const timer = setInterval(() => {
          if (this.currentNumberTop < this.number1) {
            this.currentNumberTop++;
          } else {
            clearInterval(timer);
          }
        }, 10); // Tăng số mỗi 0.1 giây (có thể điều chỉnh)
      },
      startCounting2() {
        const timer = setInterval(() => {
          if (this.currentNumberRight < this.number2) {
            this.currentNumberRight++;
          } else {
            clearInterval(timer);
          }
        }, 10); // Tăng số mỗi 0.1 giây (có thể điều chỉnh)
      },
      startCounting3() {
        const timer = setInterval(() => {
          if (this.currentNumberBottom < this.number3) {
            this.currentNumberBottom++;
          } else {
            clearInterval(timer);
          }
        }, 10); // Tăng số mỗi 0.1 giây (có thể điều chỉnh)
      },
      startCounting4() {
        const timer = setInterval(() => {
          if (this.currentNumberLeft < this.number4) {
            this.currentNumberLeft++;
          } else {
            clearInterval(timer);
          }
        }, 10); // Tăng số mỗi 0.1 giây (có thể điều chỉnh)
      },

      toMore() {
        this.$router.push({name: "about-us"});
      },
      getStyle(item) {
        return {
          'background-image': 'url(' + item.previewImage + ')'
        };
      },

      activeLeft(item) {
        this.vtActive = item.vt;
      },

      fetchData() {
        axios.get("home")
          .then(response => {
            switch (response.code) {
              case 200:
                var values = response.data;
                values.forEach((item) => {
                  // side
                  if (item.id == '1') {
                    if (item.config != null) {
                      this.dataSide = JSON.parse(item.config);
                    }
                  }
                  // về chúng tôi
                  if (item.id == '2') {
                    if (item.config != null) {
                      this.dataVct = JSON.parse(item.config);
                    }

                  }
                  // Kiến tạo tương lại
                  if (item.id == '3') {
                    if (item.config != null) {
                      this.dataKttl = JSON.parse(item.config);
                      this.number1 = this.dataKttl.top.text1;
                      this.number2 = this.dataKttl.right.text1;
                      this.number3 = this.dataKttl.bottom.text1;
                      this.number4 = this.dataKttl.left.text1;
                    }

                  }
                  // Dự án đang đầu tư
                  if (item.id == '4') {
                    this.listDataRight = [];
                    if (item.config != null) {
                      this.dataDaddt = JSON.parse(item.config);
                      this.dataDaddt.logo1.vt = 1;
                      this.dataDaddt.logo2.vt = 2;
                      this.dataDaddt.logo3.vt = 3;
                      this.dataDaddt.logo4.vt = 4;
                      this.dataDaddt.logo5.vt = 5;
                      this.dataDaddt.logo6.vt = 6;
                      this.dataDaddt.logo7.vt = 7;
                      this.dataDaddt.logo8.vt = 8;


                      this.listDataRight.push(this.dataDaddt.logo1);
                      this.listDataRight.push(this.dataDaddt.logo2);
                      this.listDataRight.push(this.dataDaddt.logo3);
                      this.listDataRight.push(this.dataDaddt.logo4);
                      this.listDataRight.push(this.dataDaddt.logo5);
                      this.listDataRight.push(this.dataDaddt.logo6);
                      this.listDataRight.push(this.dataDaddt.logo7);
                      this.listDataRight.push(this.dataDaddt.logo8);
                    }
                  }
                  // đối tác của chúng tôi
                  if (item.id == '6') {
                    if (item.config != null) {
                      this.dataDt = JSON.parse(item.config);
                    }
                  }
                  // Truyền thông tin tức
                  if (item.id == '5') {
                    if (item.config != null) {
                      this.dataTt = JSON.parse(item.config);
                      if (this.dataTt.layout == '1') {
                        this.filter.per_page = 5;
                      } else if (this.dataTt.layout == '2') {
                        this.filter.per_page = 6;
                      } else if (this.dataTt.layout == '3') {
                        this.filter.per_page = 4;
                      }
                    }
                    this.fetchPost();
                    this.handleScroll()
                  }
                })

                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                });
                break;
            }
          });
      },
      changeCurrentPage(currentPage) {
        this.filter.page = currentPage;
        this.fetchPost();
      },

      fetchPost() {
        axios.get("post", {params: this.filter})
          .then(response => {
            switch (response.code) {
              case 200:
                this.listPost = response.data.items;
                this.pagination.total = response.data.pagination.total;
                this.pagination.per_page = response.data.pagination.per_page;
                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                });
                break;
            }
          });
      },

    },

    watch: {},
  };
</script>

<style lang="css" scoped src="@/static/css/home/index.css">
  .home-index {
  }
</style>
<style>
.notranslate {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
  .home-index .el-carousel__container {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  @media only screen and (max-width: 768px), (max-width: 1023px) {
    .home-index .el-carousel__container {
      height: 180px;
    }
  }

  @media only screen and (min-width: 1201px) {
    .home-index .el-carousel__container {
      padding-bottom: 18% !important;
    }
  }
</style>
