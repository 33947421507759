<template>
  <div class="menu-left">
    <el-drawer
      :visible.sync="drawer"
      :direction="'rtl'"
      size="40%"
      :with-header="false"
      custom-class="drawer-change"
    >
      <div class="sub_menu_left">
        <div class="txt">
          <i style="cursor: pointer" class="el-icon-close" @click="drawer = false"></i>
        </div>
        <div class="txt" @click="goHome()">
          <span>Trang chủ</span>
        </div>
        <div class="txt" @click="goAboutUs()">
          <span>Về chúng tôi</span>
        </div>
        <!--<div class="txt" @click="goLVDT()">
          <span>Lĩnh vực đầu tư</span>
        </div>-->
        <div class="txt"  @click="goToTinTuc()">
          <span>Tin tức</span>
        </div>
        <div class="txt" @click="goToTuyenDung()">
          <span>Tuyển dụng</span>
        </div>
        <div class="txt" @click="goToLienHe()">
          <span>Liên hệ</span>
        </div>
      </div>
    </el-drawer>



  </div>
</template>

<script>
  import EventBus from '@/EventBus'

  export default {
    name: "HomeIndex",

    metaInfo() {
      return {
        title: "Menu",
      };
    },

    data() {
      return {
        loading: true,
        isCollapse: true,
        drawer: false,

      };
    },

    mounted() {
      EventBus.$on('showMenu', this.showMenu);
    },
    computed: {},
    methods: {

      showMenu() {
        this.drawer = !this.drawer;
      },
      goLVDT() {
        this.drawer = false;
      },
      goToTuyenDung() {
        this.$router.push({ name: "recruitment" });
        this.drawer = false;
      },
      goToTinTuc(){
        this.$router.push({ name: "new" });
        this.drawer = false;
      },
      goToLienHe() {
        this.$router.push({ name: "contact" });
        this.drawer = false;
      },
      goAboutUs() {
        this.$router.push({ name: "about-us" });
        this.drawer = false;
      },
      goHome() {
        this.$router.push({ name: "home-index" });
        this.drawer = false;
      },
    },
    watch: {},
  };
</script>

<style lang="css" scoped src="@/static/css/layout/menuLeft.css">

</style>
<style>
  .drawer-change {
    background-image: url("@/static/icon/menu-left/bgmenu.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: unset !important;
    box-shadow: none !important;
  }
</style>
